import { Controller } from "@hotwired/stimulus"
import { desktopBreakpoint } from '../../config';
import { toggleDisabledOnInput, updateTextCount, removeRedirectErrors, instantiateMapAndRender, mapHasNotBeenInstantiated } from './index';

const mapIdentifier = 'mobileMap';
let userHasSelectedToShow = false;
let hasVisitedFormStep2 = false;

export default class extends Controller {
  // Initial page load
  connect() {
    if ($(window).width() < desktopBreakpoint) {
      // Only load the map if certain conditions are met on initial page load:
      // 1. The map is not already initialized
      // 2. The form is on the page for the map (form_step=2)
      // 3. There are less than the maximum number of places allowed to show the map (max_places=4)
      // 4. Screen size is mobile (width < 576px)
      // NOTE: The map rending is handled in the handleMapCollapse method
      this.handleFormStepDisplay(true);
      this.updateNameCount();
      this.updateDescCount();
    }
  }

  submitForm() {
    const newForm = $('#new_my_story');
    const firstFormStepInputs = ['my_story_name', 'my_story_description'];
    const invalidInputs = $(newForm).find('input:invalid, textarea:invalid');

    if (invalidInputs.length > 0) {
      // get first invalid input
      const invalidInput = invalidInputs[0];

      // navigate to the correct tab view
      const isFirstFormStepInput = $.inArray(invalidInput.id, firstFormStepInputs) > -1;

      if (isFirstFormStepInput) {
        this.navigateToFormStep1();
      } else {
        this.navigateToFormStep2();
      }
    }
  }

  navigateToFormStep1() {
    removeRedirectErrors();
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set('form_step', '1');
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    this.handleFormStepDisplay();
    window.scrollTo(0, 0); // Scroll to top of page
  }

  navigateToFormStep2() {
    removeRedirectErrors();
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set('form_step', '2');
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    this.handleFormStepDisplay();
    window.scrollTo(0, 0); // Scroll to top of page
  }

  handleMapCollapse() {
    userHasSelectedToShow = !userHasSelectedToShow;
    if (userHasSelectedToShow) {
      $('.my-story-map-container').addClass('show');
    } else {
      $('.my-story-map-container').removeClass('show');
    }
    // Check that the map is not already initialized
    if (mapHasNotBeenInstantiated(mapIdentifier)) {
      // Delay the request so that the collapse has time to show before rendering the map
      // Otherwise, the map will be cutoff at the bottom of the collapse.
      const delay = 1000;
      instantiateMapAndRender(mapIdentifier, delay);
    }
  }

  handleFormStepDisplay(initialLoad=false) {
    let urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has('form_step') || urlParams.get('form_step') === '1') {
      $('.form-step-1').removeClass('zd-none');
      $('.form-step-2').addClass('zd-none');
      // Only present on form step 2
      // Make sure to collapse the map preview if it is open but only on initial load
      $('.my-story-map-container').removeClass('show');
      // resize the obfuscator to reduce page height based on form size
      // Get size of form-step-2 and mobile-map
      let formStep2Height = $('.form-step-2').height();
      let mobileMapHeight = $('.my-story-map-container').height();
      $('.obfuscator').height(`${formStep2Height + mobileMapHeight + 100}px`);
      $('.obfuscator').removeClass('rounded-top-border');

      $('.nav-buttons.form-step-1').removeClass('d-none');
      $('.nav-buttons.form-step-2').addClass('d-none');
      $('.collapse-btn').addClass('d-none');
    } else if (urlParams.get('form_step') === '2'){
      $('.form-step-1').addClass('zd-none');
      $('.form-step-2').removeClass('zd-none');

      // Make sure to display the necessary assets for form step 2
      $('.nav-buttons.form-step-1').addClass('d-none');
      $('.nav-buttons.form-step-2').removeClass('d-none');

      // resize the obfuscator to reduce page height based on form size
      // Get size of form-step-1
      let formStep1Height = $('.form-step-1').height();
      $('.obfuscator').height(`${formStep1Height + 100}px`);

      // Get places count (first char) from text and if places are present then add rounded border to top of form
      // Open the map if places are present and less than 4
      let placeCount = $('.places-count-text').text()[0];
      placeCount = parseInt(placeCount);
      if (placeCount != 0) {
        $('.obfuscator').addClass('rounded-top-border');
        $('.collapse-btn').removeClass('d-none');

        if (initialLoad || !hasVisitedFormStep2) {
          if (placeCount < 4) {
            $('.my-story-map-container').addClass('show');
            if (mapHasNotBeenInstantiated(mapIdentifier)) {
              instantiateMapAndRender(mapIdentifier);
            }
            userHasSelectedToShow = true;
          } else {
            $('.my-story-map-container').removeClass('show');
            userHasSelectedToShow = false;
          }
          hasVisitedFormStep2 = true
        } else {
          if (userHasSelectedToShow) {
            $('.my-story-map-container').addClass('show');
            if (mapHasNotBeenInstantiated(mapIdentifier)) {
              instantiateMapAndRender(mapIdentifier);
            }
          } else {
            $('.my-story-map-container').removeClass('show');
          }
        }
      }
    }
  }

  updateNameCount() {
    updateTextCount('#my_story_name', '#name-max-length');
  }

  updateDescCount() {
    updateTextCount('#my_story_description', '#description-min-length');
  }

  placesUrlToggleDisabled() {
    toggleDisabledOnInput('#my_story_place_full');
  }
}
