document.addEventListener("turbo:load", function () {
  $('body').on('click', '.place-title', function () {
    let dataset = this.dataset;
    if (dataset.showLink === 'true'){
        $("#wrapper").addClass("sb-closed");
        $('#modal-window').find('.modal-content').load(dataset.link);
        $('#modal-window').modal();
        // Highlight the selected marker on the map
        $('.leaflet-marker-icon').map(function () {
          let marker = $(this);
          if (marker.hasClass(dataset.id)) {
            marker.addClass('selected-marker');
          } else {
            marker.removeClass('selected-marker');
          }
        });
      } else {
        alert('Please sign in to view place details.');
      }
    });
});