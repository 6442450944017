import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal"]

  connect() {
    document.getElementById('modal').style.display = 'block';
  }

  remove() {
    this.element.remove()
    document.getElementById('place-detail').style.display = 'none';
    document.querySelector('.sidebar-menu').style.display = 'block';
    document.getElementById('modal').style.display = 'none';
  }
  // hide modal when clicking outside of modal
  // action: "click@window->modal#closeBackground"
  closeBackground(e) {
    if (e && !this.element.contains(e.target) && document.getElementById('page-content-wrapper').contains(e.target)) {
      this.element.remove()
      document.getElementById('place-detail').style.display = 'none';
      document.querySelector('.sidebar-menu').style.display = 'block';
      document.getElementById('modal').style.display = 'none';
    }
  }
}
